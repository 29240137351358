
.popover_class::before{
    content: '';
    position: absolute;
    bottom: 25px;
    right: 16px;
    border-top: 10px solid white;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid transparent;
    z-index: 9999;
}