
.Mui-active svg{
    color: #ffffff!important;
    border: 2px solid #6F7EF9!important;
    border-radius: 100%;
}
.Mui-active svg circle{
    color: #ffffff!important;
}

.Mui-active svg text{
    fill: #6F7EF9;
}

.Mui-disabled svg{
    color: #ffffff!important;
    border: 2px solid #eaeaf0!important;
    border-radius: 100%;
}
.Mui-disabled svg circle{
    color: #ffffff!important;
}

.Mui-disabled svg text{
    fill: #eaeaf0;
}


.MuiSvgIcon-root {
    font-size: 40px!important;
}